import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import FeaturedIn from '../components/Homepage/FeaturedIn'
import EmbedVideo from '../components/common/EmbedVideo'
import TestimonialCarousel from '../components/Homepage/TestimonialCarousel'
import WhyWereDifferent from '../components/Homepage/WhyWereDifferent'
import employeeExperience from '../images/employer/employee-experience.png'
import employeeSignup from '../images/employer/employee-sign-up.png'
import marketingToolkit from '../images/employer/internal-marketing-toolkit.png'
import reportingPlatform from '../images/employer/reporting-platform.png'
import Checkmark from '../../assets/checkmark.svg'
import manStretching from '../images/employer/man-stretching.png'
import TrustedByLong from '../components/Homepage/TrustedByLong'

const buttonPrimary =
  'inline-flex items-center justify-center bg-gp-violet hover:bg-gp-violet-70 hover:no-underline focus:bg-gp-violet-70 transition-all duration-300 text-white px-10 rounded h-12'

const buttonWhite =
  'inline-flex items-center justify-center bg-white hover:bg-gp-slate-30 hover:no-underline focus:bg-gp-slate-30 transition-all duration-300 text-gp-grey-100 px-10 rounded h-12'

const styledNumber = 'text-gp-slate text-5xl font-bold mr-6 mb-2'

const h3 = 'text-2xl text-center md:mt-6 text-gp-grey-100 mb-8 px-6'

const EmployerPage = ({ location }) => {
  return (
    <>
      <div className="sofia">
        <SEO
          title="Partner With Us"
          description="Providing custom programs for musculoskeletal pain, behavioral health, and sleep and gut issues. GoodPath helps employers reduce direct medical and pharmaceutical spend."
          location={location}
        />

        <section className="bg-gp-violet-70">
          <div className="flex flex-col items-center justify-center px-8 pt-4 pb-0 mx-auto md:container md:flex-row">
            <div className="mb-8 md:mb-0">
              <h1 className="block mb-6 text-2xl leading-tight text-white md:text-4xl lg:text-5xl">
                Addressing MSK, sleep, and digestive health through personalized
                integrative care.
              </h1>

              <Link to="/partner" className={buttonWhite}>
                Request a demo
              </Link>
            </div>

            <img
              src={manStretching}
              alt="A man stretching."
              className="max-w-5/12 sm:max-w-380"
            />
          </div>
        </section>
        <div className="mx-auto">
          {/* Trusted by */}
          <TrustedByLong />
          <section className="px-8 mx-auto my-8 bg-white md:container">
            <div className="md:grid md:grid-cols-30-70 md:items-center">
              <div className="mr-6">
                <h2 className="mb-2 text-2xl leading-snug xl:text-5xl text-gp-grey-80 md:mb-6">
                  What is Goodpath's Integrative Approach?
                </h2>
                <p className="mb-4 text-base leading-normal text-gp-grey-90 md:text-lg">
                  Learn how we provide a much better care experience by treating
                  the whole person, not just symptoms and by focusing on
                  nutrition, supplements and medicine, mind-body, and exercise.
                </p>
              </div>

              <div className="relative">
                <div
                  id="video"
                  className="relative z-10 py-0 md:px-12 md:mx-auto md:mt-16"
                >
                  <EmbedVideo videoId="3dcsgwvk5f" autoplay={false} />
                </div>
                <div className="absolute top-0 left-0 z-0 hidden md:block">
                  {/* Shapes behind video player, shows on >medium breakpoints */}
                  <svg
                    width={888}
                    height={606}
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g data-name="Shapes">
                      <circle cx="782.5" cy="105.5" r="105.5" fill="#db786b" />
                      <path
                        d="M1.5 138H0v53h1.5a60.5 60.5 0 010 121H0v53h1.5a113.5 113.5 0 000-227z"
                        fill="#60797f"
                      />
                      <path
                        d="M546.11 505c2.65 56.18 54.64 101 118.39 101s115.74-44.82 118.39-101z"
                        fill="#eec944"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center mt-6 md:py-12 md:mt-24">
              <Link to="/partner" className={buttonPrimary}>
                Request a demo
              </Link>
            </div>
          </section>
          <section className="bg-gp-slate-10 xl:relative">
            <div className="absolute right-0 hidden xl:block top-9">
              {/* Accent half-circle in experience section, shows >XL breakpoints */}
              <svg width={149} height={350} xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M149 0C65.57 7.414 0 82.933 0 175s65.57 167.586 149 175z"
                  fill="#F6E4A1"
                  fillRule="evenodd"
                />
              </svg>
            </div>
            <div className="px-8 pt-8 mx-auto md:container">
              <h2 className="mb-2 text-4xl font-thin leading-snug md:text-5xl text-gp-grey-100 md:mb-6 md:text-center">
                The Employee Experience
              </h2>
              <div className="md:grid md:grid-cols-30-70">
                <div className="px-0 pb-0 mb-6 text-base md:mb-0 md:mt-6 md:pb-16 md:text-lg">
                  <div className="flex items-center pb-3">
                    <span className={styledNumber}> 1 </span>
                    <p className="leading-normal text-gp-grey-90">
                      Take Goodpath's 360° assessment
                    </p>
                  </div>
                  <div className="flex items-center pb-3 md:pb-2">
                    <span className={styledNumber}> 2 </span>
                    <p className="leading-normal text-gp-grey-90">
                      Receive a unique care program consisting of:
                    </p>
                  </div>
                  <div className="leading-relaxed md:px-12 text-gp-grey-90">
                    <div className="flex mb-3">
                      <Checkmark className="block mt-2 mr-2" />
                      <div className="flex w-full leading-normal">
                        Customized supplement kits delivered to their door
                        monthly.
                      </div>
                    </div>

                    <div className="flex mb-3">
                      <Checkmark className="block mt-2 mr-2" />
                      <div className="flex w-full leading-normal">
                        A certified health coach assigned to you to modify the
                        program over time and help motivate you to stick with
                        it.
                      </div>
                    </div>

                    <div className="flex mb-3">
                      <Checkmark className="block mt-2 mr-2" />
                      <div className="flex w-full leading-normal">
                        Personalized exercise regimen, mind body activities, and
                        nutrition program based on your needs.
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <span className={styledNumber}> 3 </span>
                    <p className="leading-normal text-gp-grey-90">
                      More Good Days. Start getting back to your best.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-end order-last sm:order-first">
                  <img
                    src={employeeExperience}
                    alt="A Goodpath coach and a Goodpath member opening their Goodpath kit."
                  />
                </div>
              </div>
            </div>
          </section>

          <WhyWereDifferent />
          <div className="flex flex-col items-center justify-center mb-16 md:mt-12">
            <Link to="/partner" className={buttonPrimary}>
              Request a demo
            </Link>
          </div>

          <section className="pt-8 bg-gp-honey-30 md:pb-16">
            <div className="px-8 mx-auto md:container">
              <h2 className="block mb-6 text-3xl leading-snug md:text-5xl md:font-thin text-gp-grey-100 md:text-center">
                Simple integration, seamless experience
              </h2>
              <div className="md:grid md:grid-cols-3">
                <div className="pb-16 md:pb-0">
                  <h3 className={h3}>Internal Marketing Toolkit</h3>
                  <img
                    src={marketingToolkit}
                    alt="Icon of a sample marketing tool kit brochure."
                    className="w-3/4 mx-auto"
                  />
                </div>
                <div className="pb-16 md:pb-0">
                  <h3 className={h3}>Quick &amp; easy Employee Sign-up</h3>
                  <img
                    src={employeeSignup}
                    alt="Icon of creating a profile on a mobile device."
                    className="w-3/4 mx-auto"
                  />
                </div>
                <div className="pb-16 md:pb-0">
                  <h3 className={h3}>Reporting Platform</h3>
                  <img
                    src={reportingPlatform}
                    alt="Icon of a sample reporting system."
                    className="w-3/4 mx-auto"
                  />
                </div>
              </div>
            </div>
          </section>

          <FeaturedIn />
          <TestimonialCarousel />

          <section className="flex flex-col items-center justify-center px-8 py-8 space-y-8 bg-gp-violet md:py-16 md:space-y-16">
            <h2 className="text-4xl font-thin leading-snug text-center text-white md:text-5xl">
              Empower your employees today
            </h2>
            <div className="mx-auto">
              <Link to="/partner" className={buttonWhite}>
                Request a demo
              </Link>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default EmployerPage
