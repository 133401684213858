import React, { Component } from 'react'
import personCare from '../../images/employer/whole-person-care.svg'
import dynamicPrograms from '../../images/employer/dynamic-tailored-programs.svg'
import medicalCoaching from '../../images/employer/medically-backed-health-coaching.svg'
import sustainedEngagement from '../../images/employer/sustained-engagement.svg'

const card = 'flex flex-col justify-start items-center space-y-2 md:space-y-4'
const title = 'sofia text-center text-lb md:text-2xl leading-snug'

class WhyWereDifferent extends Component {
  render() {
    return (
      <div className="bg-white px-4 pt-8 pb-4">
        <div className="max-w-7xl mx-auto flex flex-col">
          <h2 className="text-3xl md:text-5xl md:font-thin text-gp-grey-100 block mb-6 md:mb-12 leading-snug md:text-center">
            Why we're different
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-8 md:gap-x-4 md:gap-y-4 mb-6">
            <div className={card}>
              <img src={personCare} alt="" className="h-16" />
              <span className={`text-gp-violet ${title}`}>
                Whole
                <span className="block">Person Care</span>
              </span>
            </div>
            <div className={card}>
              <img src={dynamicPrograms} alt="" className="h-16" />
              <span className={`text-gp-slate ${title}`}>
                Dynamic and<span className="block">Tailored Programs</span>
              </span>
            </div>
            <div className={card}>
              <img src={medicalCoaching} alt="" className="h-16" />
              <span className={`text-gp-grapefruit ${title}`}>
                Medically Backed<span className="block">Health Coaching</span>
              </span>
            </div>
            <div className={card}>
              <img src={sustainedEngagement} alt="" className="h-16" />
              <span className={`text-gp-grey-90 ${title}`}>
                Sustained<span className="block">Engagement</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WhyWereDifferent
