import React, { Component } from 'react'
import CarouselContainer from '../Carousel/CarouselContainer'

// Partner images
import bennieLogo from '../../images/trusted-by/color/bennie-logo-color.png'
import yamahaLogo from '../../images/trusted-by/color/yamaha-logo-color-r.png'
import formaLogo from '../../images/trusted-by/color/forma-logo-color.png'
import benepassLogo from '../../images/trusted-by/color/benepass-logo-color.png'
import himsHersLogo from '../../images/trusted-by/color/hims-hers-logo-color.png'
import seventyBioLogo from '../../images/trusted-by/color/2seventy-logo-color.png'
import blueBirdBioLogo from '../../images/trusted-by/color/bluebirdbio-logo-color.png'

const trustedLogos = 'h-8 md:h-12 lg:h-16 max-w-none'
class TrustedByLong extends Component {
  render() {
    return (
      <div className="pt-8 pb-4 md:pt-12 md:pb-8 bg-gp-periwinkle-5">
        <h2 className="mb-6 text-3xl font-bold leading-tight text-center lg:text-5xl lg:mb-12 text-gp-violet">
          <span className="sr-only">Goodpath is</span>Trusted by
        </h2>
        <div className="flex flex-col mx-auto">
          <div className="pb-6">
            <div className="w-full overflow-x-hidden space-between-48 slick-list-none -px-6">
              <CarouselContainer sliderSettings={sliderSettings}>
                {companyImages.map((e, i) => (
                  <div key={i}>
                    <img src={e.url} alt={e.alt} className={trustedLogos} />
                  </div>
                ))}
              </CarouselContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TrustedByLong

const companyImages = [
  {
    url: bennieLogo,
    alt: 'Bennie logo.'
  },
  {
    url: yamahaLogo,
    alt: 'Yamaha logo.'
  },
  {
    url: formaLogo,
    alt: 'Forma logo.'
  },
  {
    url: benepassLogo,
    alt: 'Benepass logo.'
  },
  {
    url: himsHersLogo,
    alt: 'Hims & Hers logo.'
  },
  {
    url: seventyBioLogo,
    alt: 'Voda.ai logo.'
  },
  {
    url: blueBirdBioLogo,
    alt: '2seventybio logo.'
  }
]

const sliderSettings = {
  className: 'slider variable-width',
  variableWidth: true,
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  infinite: true,
  pauseOnHover: true,
  slidesToScroll: 1,
  slidesToShow: 5
}
