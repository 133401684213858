import React, { Component } from 'react'
import CarouselContainer from '../../components/Carousel/CarouselContainer'
import customTestimonialDesktop from '../../images/homepage/custom-testimonial-desktop.jpg'
import customTestimonialMobile from '../../images/homepage/custom-testimonial-mobile.png'
import qualityTestimonialDesktop from '../../images/homepage/quality-testimonial-desktop.jpg'
import qualityTestimonialMobile from '../../images/homepage/quality-testimonial-mobile.png'
import sleepTestimonialDesktop from '../../images/homepage/sleep-testimonial-desktop.jpg'
import sleepTestimonialMobile from '../../images/homepage/sleep-testimonial-mobile.png'
import spentTestimonialDesktop from '../../images/homepage/spent-testimonial-desktop.jpg'
import spentTestimonialMobile from '../../images/homepage/spent-testimonial-mobile.png'

class TestimonialCarousel extends Component {
  render() {
    return (
      <div className="bg-gp-violet-30 pt-8 pb-8 lg:pb-12">
        <div className="px-6 lg:px-12">
          <div className="md:hidden w-full mx-auto mb-6">
            <CarouselContainer sliderSettings={sliderSettingsTestimonials}>
              {testimonialMobileImages.map((e, i) => (
                <div key={i}>
                  <img src={e.url} alt={e.alt} />
                </div>
              ))}
            </CarouselContainer>
          </div>
          <div className="hidden md:block w-full lg:w-3/4 mx-auto mb-6 shadow-lg">
            <CarouselContainer sliderSettings={sliderSettingsTestimonials}>
              {testimonialImages.map((e, i) => (
                <div key={i}>
                  <img src={e.url} alt={e.alt} />
                </div>
              ))}
            </CarouselContainer>
          </div>
        </div>
      </div>
    )
  }
}

const testimonialMobileImages = [
  {
    url: customTestimonialMobile,
    alt: 'A testimonial showcasing the great personalized Goodpath programs.'
  },
  {
    url: qualityTestimonialMobile,
    alt:
      'A testimonial showcasing improved quality of life with a Goodpath progam.'
  },
  {
    url: sleepTestimonialMobile,
    alt:
      'A testimonial showcasing a member who was able to stop taking sleeping pills using a Goodpath program.'
  },
  {
    url: spentTestimonialMobile,
    alt:
      'A testimonial showcasing the value of a Goodpath program versus so many disjointed doctor visits.'
  }
]

const testimonialImages = [
  {
    url: customTestimonialDesktop,
    alt: 'A testimonial showcasing the great personalized Goodpath programs.'
  },
  {
    url: qualityTestimonialDesktop,
    alt:
      'A testimonial showcasing improved quality of life with a Goodpath progam.'
  },
  {
    url: sleepTestimonialDesktop,
    alt:
      'A testimonial showcasing a member who was able to stop taking sleeping pills using a Goodpath program.'
  },
  {
    url: spentTestimonialDesktop,
    alt:
      'A testimonial showcasing the value of a Goodpath program versus so many disjointed doctor visits.'
  }
]

const sliderSettingsTestimonials = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 12000,
  speed: 800,
  dots: true,
  centerMode: false,
  infinite: true,
  pauseOnHover: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  responsive: []
}

export default TestimonialCarousel
