import React, { Component } from 'react'
import allWork from '../../images/featured-in/all-work-logo.png'
import benefitsPro from '../../images/featured-in/benefits-pro-logo.png'
import bostinno from '../../images/featured-in/bostinno-logo.png'
import ebn from '../../images/featured-in/ebn-logo.png'
import ehsToday from '../../images/featured-in/ehstoday-logo.png'
import foodNetwork from '../../images/featured-in/food-network-logo.png'
import nasdaq from '../../images/featured-in/nasdaq-logo.png'

const featuredLogos = 'h-4 md:h-5 xl:h-8 mb-8 md:mb-12 mx-3 md:mx-8 xl:mx-16'

class FeaturedIn extends Component {
  render() {
    return (
      <div className="bg-gp-off-white px-4 pt-8 pb-4">
        <div className="max-w-7xl mx-auto flex flex-col">
          <h2 className="text-gp-grey-70 text-center font-bold text-xl md:text-3xl mb-8">
            <span className="sr-only">Goodpath is</span>Featured in
          </h2>
          <div className="flex flex-wrap justify-around md:justify-center md:items-center">
            <a
              href="https://allwork.space/2020/12/is-self-care-the-future-of-workplace-wellness-a-qa-with-goodpath-co-founder-bill-gianoukos/"
              target="_blank"
              rel="noreferrer"
              className="logo_link transition"
            >
              <img
                src={allWork}
                alt="allwork logo."
                className={featuredLogos}
              />
            </a>
            <a
              href="https://www.benefitspro.com/2021/01/13/why-2021-will-be-the-year-benefits-packages-leverage-integrative-care/"
              target="_blank"
              rel="noreferrer"
              className="logo_link transition"
            >
              <img
                src={benefitsPro}
                alt="Benefits Pro logo."
                className={featuredLogos}
              />
            </a>
            <a
              href="https://www.bizjournals.com/boston/inno/stories/startups-to-watch/2020/12/15/21-startups-to-watch-in-boston-in-2021-bostinno.html"
              target="_blank"
              rel="noreferrer"
              className="logo_link transition"
            >
              <img
                src={bostinno}
                alt="Bostinno logo."
                className={featuredLogos}
              />
            </a>
            <a
              href="https://www.benefitnews.com/news/why-your-employees-need-integrative-wellness-benefits"
              target="_blank"
              rel="noreferrer"
              className="logo_link transition"
            >
              <img src={ebn} alt="ebn logo." className={featuredLogos} />
            </a>
            <a
              href="https://www.ehstoday.com/health/article/21154120/can-integrative-health-solutions-help-employees-while-cutting-costs"
              target="_blank"
              rel="noreferrer"
              className="logo_link transition"
            >
              <img
                src={ehsToday}
                alt="EHS Today logo."
                className={featuredLogos}
              />
            </a>
            <a
              href="https://www.foodnetwork.com/healthyeats/healthy-tips/new-rules-on-sleep-2021"
              target="_blank"
              rel="noreferrer"
              className="logo_link transition"
            >
              <img
                src={foodNetwork}
                alt="Food Network logo."
                className={featuredLogos}
              />
            </a>
            <a
              href="https://www.nasdaq.com/articles/how-companies-are-reimagining-health-benefits-for-2021-2021-01-04"
              target="_blank"
              rel="noreferrer"
              className="logo_link transition"
            >
              <img src={nasdaq} alt="Nasdaq logo." className={featuredLogos} />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default FeaturedIn
